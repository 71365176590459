import React from "react";
import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import personFill from "@iconify/icons-eva/person-fill";
import unlockFill from "@iconify/icons-eva/unlock-fill";
import copyFill from "@iconify/icons-eva/copy-fill";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
import MenuPopover from "../../components/MenuPopover";
import { delegte_logut_api } from "../../DAL/Login/Login";
import ChangePassword from "./ChangePassword";
import { get_root_value } from "src/utils/domUtils";
import { _get_user_from_localStorage } from "src/DAL/localstorage/LocalStorage";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import CopyToClipboard from "react-copy-to-clipboard";
import { useEffect } from "react";
import ChangeAffliateId from "./ChangeAffliateId";
import ConfirmPassword from "src/components/ConfirmPassword";

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openAffliateModal, setopenAffliateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedValue, setSelectedValue] = useState("this_device");
  const [open, setOpen] = useState(false);
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const { userInfo, STOP_SOCKET_FORCEFULLY } = useContentSetting();
  const { STOP_CHAT_FORCEFULLY } = useChat();
  const { pathname } = useLocation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfile = (value) => {
    navigate(value);
    handleClose();
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar(" ID copied to clipboard", { variant: "success" });
  };

  const handleAppCopiedUrl = () => {
    enqueueSnackbar("App ID copied to clipboard", { variant: "success" });
  };

  const handleLogout = async () => {
    setOpenConfirm(true);
  };

  const delegte_logut = async () => {
    setIsLoading(true);
    const result = await delegte_logut_api(selectedValue);
    if (result.code === 200) {
      STOP_SOCKET_FORCEFULLY();
      STOP_CHAT_FORCEFULLY();
      localStorage.clear();
      navigate("/login");
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    // {
    //   label: "Home",
    //   icon: homeFill,
    //   linkTo: "/",
    // },
    {
      label: "Edit Profile",
      icon: personFill,
      linkTo: "/edit-profile",
    },
    // {
    //   label: "Zoom Setting",
    //   icon: personFill,
    //   linkTo: "/zoom-setting",
    // },

    // {
    //   label: "Settings",
    //   icon: settings2Fill,
    //   linkTo: "#",
    // },
  ];

  useEffect(() => {
    setTimeout(() => {
      const is_path_includes = (path) => {
        return pathname.toString().includes(path);
      };
      if (
        !is_path_includes("/self-image-answers") &&
        !is_path_includes("/dynamite-event-videos/video-question/answers") &&
        !is_path_includes("/website-pages/answers")
      ) {
        localStorage.removeItem("questions_answers_search");
      }
      if (!is_path_includes("/booking")) {
        localStorage.removeItem("booking_filter_data");
      }
      if (!is_path_includes("/nurture-members")) {
        localStorage.removeItem("nurture_member_filter_data");
        localStorage.removeItem("nurture_page_number");
        localStorage.removeItem("nurture_rows");
      }
      if (!is_path_includes("/all-members")) {
        localStorage.removeItem("all_member_filter_data");
        localStorage.removeItem("all-member-row");
        localStorage.removeItem("all_member_page_number");
      }
      if (!is_path_includes("/member-answers-list")) {
        localStorage.removeItem("question_answers_filter_data");
      }
      if (!is_path_includes("/contact-support")) {
        localStorage.removeItem("contact_supporrt_data");
      }
      if (!is_path_includes("/members")) {
        localStorage.removeItem("member_filter_data");
        localStorage.removeItem("member_page_number");
        localStorage.removeItem("member_rows");
      }
      if (!is_path_includes("/booking")) {
        localStorage.removeItem("booking_filter_data");
      }
      if (!is_path_includes("/source-pods/pods-detail")) {
        localStorage.removeItem("pods_search_text");
      }
      if (!is_path_includes("/assessment")) {
        localStorage.removeItem("Assessment_search");
      }
      if (!is_path_includes("/leads-center")) {
        localStorage.removeItem("leads_centre_filter");
        localStorage.removeItem("leads_centre_searches");
      }
      if (!is_path_includes("/accountability-tracker-report")) {
        localStorage.removeItem("sales_performance_filter_data");
      }
      if (!is_path_includes("/accountability-tracker-report")) {
        localStorage.removeItem("sales_performance_filter_data_sales");
      }
      if (!is_path_includes("/support-ticket")) {
        localStorage.removeItem("waitingsearch");
        localStorage.removeItem("answeredsearch");
        localStorage.removeItem("need_fixessearch");
        localStorage.removeItem("needs_to_attentionsearch");
        localStorage.removeItem("remindersearch");
        localStorage.removeItem("ready_to_closesearch");
        localStorage.removeItem("solvedsearch");
        localStorage.removeItem("trashsearch");
      }
      if (!is_path_includes("/payment-request")) {
        localStorage.removeItem("request_filter_status");
      }
      if (!is_path_includes("/tutorials")) {
        localStorage.removeItem("teturials");
      }
      if (!is_path_includes("/digital-asset")) {
        localStorage.removeItem("digital_assests");
      }
      if (!is_path_includes("/recordings")) {
        localStorage.removeItem("recordings_search");
      }
      if (!is_path_includes("/dynamite-pods")) {
        localStorage.removeItem("dynamite_pods");
      }
      if (!is_path_includes("/my-events")) {
        localStorage.removeItem("my_portals");
      }
      if (!is_path_includes("/live-events")) {
        localStorage.removeItem("portals");
      }
      if (!is_path_includes("/groups")) {
        localStorage.removeItem("delegate_group");
      }
      if (!is_path_includes("/website-links")) {
        localStorage.removeItem("delegate_links");
      }
      if (!is_path_includes("/payment-request")) {
        localStorage.removeItem("payment_request_search");
      }
      if (!is_path_includes("/accountability-tracker-report")) {
        localStorage.removeItem("tracker_search_text");
      }
      if (!is_path_includes("/sale-team-transactions")) {
        localStorage.removeItem("affiliate_search_text");
      }
      if (!is_path_includes("/sales-team")) {
        localStorage.removeItem("sales_team_filter_data");
      }
      if (!is_path_includes("/member-responded-goalstatements")) {
        localStorage.removeItem("responded_search");
      }
      if (!is_path_includes("/internal-tickets")) {
        localStorage.removeItem("internal-search");
      }
      if (!is_path_includes("/internal-tickets/answered")) {
        localStorage.removeItem("answer-internal-search");
      }
      if (!is_path_includes("/internal-tickets/needs-attention")) {
        localStorage.removeItem("attention-internal-search");
      }
      if (!is_path_includes("/internal-tickets/solved")) {
        localStorage.removeItem("solve-internal-search");
      }
      if (!is_path_includes("/meetings")) {
        localStorage.removeItem("meeting_search_text");
      }
      if (!is_path_includes("/website-links")) {
        localStorage.removeItem("book_a_call_page_link_search");
        localStorage.removeItem("sale_page_link_search");
      }
      if (!is_path_includes("/programmes/")) {
        localStorage.removeItem("programme_tab_value");
      }
    }, 1000);
  }, [pathname]);

  return (
    <>
      <ChangePassword
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
      />
      <ChangeAffliateId
        openPasswordModal={openAffliateModal}
        setopenPasswordModal={setopenAffliateModal}
      />
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
            color: get_root_value("--portal-theme-primary"),
          }),
        }}
      >
        <Avatar
          className="top-avatar"
          src={s3baseUrl + userInfo.image?.thumbnail_1}
          alt="photoURL"
        />
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 280 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userInfo.first_name}
            &nbsp;{(" ", userInfo.last_name)}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: get_root_value("--input-text-color: #fff") }}
            noWrap
          >
            {userInfo.email}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleProfile(option.linkTo)}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
                color: get_root_value("--portal-theme-primary"),
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        <CopyToClipboard
          text={userInfo?.affiliate_url_name}
          onCopy={handleCopiedUrl}
        >
          <Tooltip title="Click to copy ID">
            <MenuItem key="" sx={{ typography: "body2", py: 1, px: 2.5 }}>
              <Box
                component={Icon}
                icon={copyFill}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                  color: get_root_value("--portal-theme-primary"),
                }}
              />
              Copy Referral Id
            </MenuItem>
          </Tooltip>
        </CopyToClipboard>{" "}
        <CopyToClipboard
          text={userInfo?.affiliate_link}
          onCopy={handleAppCopiedUrl}
        >
          <Tooltip title="Click to copy App ID">
            <MenuItem key="" sx={{ typography: "body2", py: 1, px: 2.5 }}>
              <Box
                component={Icon}
                icon={copyFill}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                  color: get_root_value("--portal-theme-primary"),
                }}
              />
              Copy App Referral Id
            </MenuItem>
          </Tooltip>
        </CopyToClipboard>
        <MenuItem
          key=""
          onClick={() => {
            handleClose();
            setopenAffliateModal(true);
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={"akar-icons:edit"}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: get_root_value("--portal-theme-primary"),
            }}
          />
          <span>Change Affiliate Id</span>
        </MenuItem>
        <MenuItem
          key=""
          onClick={() => {
            navigate(`/zoom-setting`);
            handleClose();
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={unlockFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: get_root_value("--portal-theme-primary"),
            }}
          />
          Settings
        </MenuItem>
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => handleLogout()}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
      <ConfirmPassword
        open={openConfirm}
        setOpen={setOpenConfirm}
        selected={selectedValue}
        setSelected={setSelectedValue}
        handleAgree={delegte_logut}
        isLoading={isLoading}
      />
    </>
  );
}
