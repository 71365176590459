import { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { programmesListing } from "../../DAL/Programmes/Programmes";
import { s3baseUrl } from "src/config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import { useNavigate } from "react-router-dom";
import RecordNotFound from "src/components/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Programmes() {
  const classes = useStyles();
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [programmesData, setProgrammesData] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMore, setLoadMore] = useState(
    "api/consultant/delegate_training/list?page=0&limit=10&search_text="
  );
  const navigate = useNavigate();

  const handleClick = (object) => {
    delete object.card_button;
    if (object.locked_status === false) {
      navigate(`/delegate-training/${object.program_slug}`, {
        state: object,
      });
    }
  };

  const getProgrammesList = async (load_path) => {
    const result = await programmesListing(load_path);
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.program.map((program, index) => {
        CARD_OPTIONS.push({
          ...program,
          card_title: program.title,
          card_short_description: program.short_description,
          card_image:
            index == 0 && pageNumber == 0
              ? s3baseUrl + program.program_images.thumbnail_1
              : s3baseUrl + program.program_images.thumbnail_2,
          card_classes:
            index == 0 && pageNumber == 0
              ? "col-12 wider-card"
              : "col-lg-4 col-md-6 col-sm-12 mt-4", // first index should be in 12 cols
          card_gray_description: `${program.no_of_lesson} Modules | ${program.total_lesson_duration}`,
          card_button: program.locked_status === true && {
            button_title: (
              <>
                {program?.program_lock_icon ? (
                  <img
                    src={s3baseUrl + program?.program_lock_icon}
                    className=" program_lock_img"
                  />
                ) : (
                  program?.locked_program_info?.image && (
                    <img
                      src={s3baseUrl + program?.locked_program_info.image}
                      className=" program_lock_img"
                    />
                  )
                )}

                {program.program_lock_statement
                  ? program.program_lock_statement
                  : "locked"}
              </>
            ),
            button_icon: "MUILockIcon",
            handleClick: handleClick,
          },
        });
      });
      setPageNumber((page) => page + 1);
      setTotalPages(result.total_pages);
      setLoadMore(result.load_more);
      setIsLoadingMore(false);
      setProgrammesData((old) => [...old, ...CARD_OPTIONS]);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMore, observer]
  );

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getProgrammesList(loadMore);
  };

  useEffect(() => {
    getProgrammesList(loadMore);
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container mt-3">
      {programmesData.length > 0 ? (
        <>
          <CustomMUICard
            data={programmesData}
            handleClick={handleClick}
            handleButtonClick={handleClick}
          />
          {totalPages > pageNumber ? (
            <div className="col-12 text-center mt-4">
              <span
                ref={lastBookElementRef}
                className=" mt-3"
                onClick={loadMoreData}
                id="load-more-feed"
              >
                {isLoadingMore ? (
                  <CircularProgress color="primary" />
                ) : (
                  "Load More"
                )}
              </span>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <RecordNotFound title="Programmes" />
      )}
    </div>
  );
}
