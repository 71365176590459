import React from "react";
import ReactVideoPlayer from "../_dashboard/GeneralComponents/ReactVideoPlayer";
import { s3baseUrl } from "src/config/config";

export default function ProgrammeOverview(props) {
  const { programmesDetail } = props;
  const get_programme_content = () => {
    if (programmesDetail.program_lock_status === true) {
      return {
        video_url: programmesDetail.locked_program_info.video_url,
        detailed_description:
          programmesDetail.locked_program_info.detailed_description,
        image: programmesDetail.locked_program_info.image,
      };
    } else {
      return {
        video_url: programmesDetail.video_url,
        audio_file: s3baseUrl + programmesDetail.audio_file,
        detailed_description: programmesDetail.detailed_description,
        image: programmesDetail?.program_images?.thumbnail_1,
      };
    }
  };

  return (
    <div className="row media-margin">
      <div className="col-12">
        {get_programme_content().video_url ? (
          <ReactVideoPlayer url={get_programme_content().video_url} />
        ) : get_programme_content().image ? (
          <ProgrammesImgStyle src={s3baseUrl + get_programme_content().image} />
        ) : (
          ""
        )}
      </div>
      {get_programme_content().audio_file && (
        <div className="col-12 section-space">
          <audio
            controlsList="nodownload"
            className="w-100"
            src={get_programme_content().audio_file}
            controls
          />
        </div>
      )}

      <div className="col-12 section-space set-image-center">
        <div
          dangerouslySetInnerHTML={{
            __html: get_programme_content().detailed_description,
          }}
          className="programmes-detail-description"
        ></div>
      </div>
    </div>
  );
}
