import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { get_kmb_number } from "src/utils/formatNumber";
import {
  COMMUNITY_LEVELS,
  TIME_ZONE_CONVERSION,
  TIME_ZONE_CONVERSION_new,
  api_date_format,
  dd_date_format,
  get_short_string,
} from "src/utils/constants";
import { allMemberListingForDelegate } from "src/DAL/member/Member";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";

import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MessageIcon from "@mui/icons-material/Message";
import CircleIcon from "@mui/icons-material/Circle";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import ChangeLeadStatus from "../../components/MembersList/ChangeLeadStatus";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { downloadIcon, notDownloadIcon, wheelIcon } from "src/assets";
import PersonalNotes from "src/components/MembersList/PersonalNotes";
import PagesList from "src/components/MembersList/PagesList";
import FilteredChip from "src/components/GeneralComponents/FilteredChip";
import MemberFilter from "src/components/MembersList/MemberFilter";
import ProgramsList from "src/components/MembersList/ProgramsList";
import GeneralPopOver from "../../components/GeneralComponents/GeneralPopOver";
import moment from "moment";
import ChangeLeadStatusForList from "src/components/MembersList/ChangeLeadStatusForList";
import { active_lead_status_api } from "src/DAL/leadStatus/leadStatus";
import LeadStatusHistory from "./LeadStatusHistory";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import HistoryIcon from "@mui/icons-material/History";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { download_csv_file, urlify } from "src/utils/convertHtml";
import CustomPopover from "src/components/CustomPopover";
import CallTypeNotes from "./CallTypeNotes";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { update_member_call_alowed_api } from "src/DAL/LeadsCenter/lead_stages";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const sort_by_array = [
  {
    chip_label: "Registration Date (Oldest First)",
    chip_value: "registration_date_asc",
  },
  {
    chip_label: "Registration Date (Newest First)",
    chip_value: "registration_date_desc",
  },
  {
    chip_label: "Membership Expiry Date (Oldest First)",
    chip_value: "membership_expiry_date_asc",
  },
  {
    chip_label: "Membership Expiry Date (Newest First)",
    chip_value: "membership_expiry_date_desc",
  },
  {
    chip_label: "Last Login Date (Oldest First)",
    chip_value: "last_login_date_asc",
  },
  {
    chip_label: "Last Login Date (Newest First)",
    chip_value: "last_login_date_desc",
  },
];
const EMPTY_FILTER = {
  community: [],
  event_page: null,
  lead_status: [],
  sort_by: {
    chip_label: "Registration Date (Newest First)",
    chip_value: "registration_date_desc",
  },
  plan: null,
  nurture: null,
  delegate: null,
  is_date_range: false,
  coins_range: false,
  coins_from: 0,
  coins_to: 0,
  from_date: null,
  to_date: null,
  membership_purchase_expiry_from: new Date(),
  membership_purchase_expiry_to: new Date(),
  date: null,
  coins: null,
  membership_expiry: null,
  member_user_type: "",
  status: "",
  expiry_in: 3,
  member_ship_expiry: "",
  user_status_type: "",
  country: null,
  downloaded_app: null,
};

const REMOVE_ITEMS = ["RowsNumber"];

export default function AllMembersList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [membersData, setMembersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElProgram, setAnchorElProgram] = useState(null);
  const { handleAddChat, onlineOfflineData } = useChat();
  const [dataList, setDataList] = useState([]);
  const [inputsUpdated, setInputsUpdated] = useState({ lead_status: null });
  const [leadStatusOld, setleadStatusOld] = useState({});
  const [ischangeFilter, setIschangeFilter] = useState(false);
  const [final, setfinal] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [leadStatusData, setleadStatusData] = useState({});
  const [openDiv, setOpenDiv] = useState(null);
  const [selectedMember, setSelectedMember] = useState({});
  const [isReload, setIsReload] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [formDataUpdated, setformDataUpdated] = useState(EMPTY_FILTER);
  const [leadStatus, setLeadStatus] = useState([]);
  const open_program_popover = Boolean(anchorElProgram);
  const program_popover_id = open ? "simple-popover" : undefined;
  const open_pages_popover = Boolean(anchorEl);
  const pages_popover_id = open ? "simple-popover-pages" : undefined;
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [openCallType, setOpenCallType] = useState(false);
  const [index, setindex] = useState(null);
  const [memberObject, setMemberObject] = useState({});
  const [history, sethistory] = useState([]);
  const [openAllowCall, setOpenAllowCall] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [SaveFilter, setSaveFilter] = useState({
    filter_name: null,
    filter_From: "new",
  });
  const { userInfo, adminTimeZone, isAccess } = useContentSetting();
  let consultant_both = false;

  if (isAccess.view_profile) {
    consultant_both = true;
  }
  const change_lead_status_history = (value) => {
    setleadStatusData(value);
    setChangeStatusOpen(true);
  };
  const handleChangeLeadStatus = (value, row) => {
    setMembersData((old) => {
      return old.map((item) => {
        if (item._id == row._id) {
          return {
            ...item,
            lead_status: value,
          };
        }
        return item;
      });
    });
    setleadStatusOld(row);
    change_lead_status({
      ...row,
      lead_status: value,
    });
  };
  const handleCrossedLeadStatis = () => {
    handleUpdateStatus(
      leadStatusOld.lead_status,
      leadStatusOld.lead_status_history
    );
  };
  const handle_chips_data = (flterData, formData) => {
    let chipData = { ...flterData };
    if (chipData.is_date_range) {
      let date = `Start Date : ${dd_date_format(
        chipData.from_date
      )} - End Date :  ${dd_date_format(chipData.to_date)}`;
      chipData.date = {
        chip_label: date,
        chip_value: date,
      };
    }
    if (chipData.coins_range) {
      let coins = `Start Coins : ${chipData.coins_from} - End Coins :  ${chipData.coins_to}`;
      chipData.coins = {
        chip_label: coins,
        chip_value: coins,
      };
    }
    if (chipData.member_ship_expiry == "not_expired") {
      let membership_date = "";
      if (chipData.expiry_in !== "custom") {
        membership_date = `Expiry in ${chipData.expiry_in} days`;
      } else {
        membership_date = `Membership Expiry Start Date : ${dd_date_format(
          chipData.membership_purchase_expiry_from
        )} - Membership Expiry End Date :  ${moment(
          formData.membership_purchase_expiry_to,
          "YYYY-MM-DD"
        ).format("DD-MM-YYYY")}`;
      }

      chipData.membership_expiry = {
        chip_label: membership_date,
        chip_value: membership_date,
      };
    }
    setFilterData({ ...chipData });
    localStorage.setItem(
      "all_member_filter_data",
      JSON.stringify({ ...chipData })
    );

    if (chipData.member_ship_expiry == "not_expired") {
      delete chipData.member_ship_expiry; //not show not_expired in chip
    }

    // not show these variables in chip
    delete chipData.from_date;
    delete chipData.to_date;
    delete chipData.coins_from;
    delete chipData.coins_to;
    delete chipData.membership_purchase_expiry_from;
    delete chipData.membership_purchase_expiry_to;
    delete chipData.expiry_in;
    delete chipData.search_text;
    setformDataUpdated(chipData);
  };

  const handleClickPopUP = (event, data) => {
    setDataList(data);
    setAnchorElProgram(event.currentTarget);
  };

  const change_lead_status = (value) => {
    setSelectedMember(value);
    setChangeStatus(true);
  };

  const handleClickPopUPPages = (event, data) => {
    setDataList(data);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleOpenLeadStatus = () => {
    setChangeStatus(true);
  };

  const handleCloseLeadStatus = () => {
    setChangeStatus(false);
    setleadStatusOld({});
    setSelectedMember({});
  };

  const handleClosePopUp = () => {
    setAnchorElProgram(null);
  };
  const handleClosePopUpPages = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
    setIschangeFilter(true);
  };
  const handleIsCountry = (event) => {
    setFilterData((values) => ({ ...values, country: event }));
  };
  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
    setIschangeFilter(true);
  };
  const handleChangeSort = (event, name) => {
    let filter_Data = {
      ...filterData,
      [name]: event,
    };
    setFilterData(filter_Data);
    getMembersList(filter_Data);
    localStorage.setItem("all_member_filter_data", JSON.stringify(filter_Data));
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("all_member_page_number", newPage);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    localStorage.removeItem("all_member_filter_data");
    localStorage.removeItem("all-member_save");
    setSaveFilter({
      filter_name: null,
      filter_From: "new",
    });
    getMembersList(EMPTY_FILTER);
    setFilterDrawerState(false);
    setfinal(false);
    setIschangeFilter(false);
  };

  const get_start_date_end_time = (date) => {
    return TIME_ZONE_CONVERSION_new(
      date,
      "DD-MM-YYYY hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };
  const handleOpenDiv = (index) => {
    setOpenDiv(index);
  };

  const getMembersList = async (flterData) => {
    setIsLoading(true);

    let formData = { ...flterData };
    if (formData.downloaded_app) {
      formData.downloaded_app = formData.downloaded_app.value;
    }
    if (formData.country) {
      formData.country = formData.country.code;
    }
    formData.coins_from = +formData.coins_from;
    formData.coins_to = +formData.coins_to;
    if (formData.event_page) {
      formData.event_page = [formData.event_page._id];
    } else {
      formData.event_page = [];
    }
    if (formData.plan) {
      formData.plan = formData.plan._id;
    }
    if (formData.nurture) {
      formData.nurture = formData.nurture._id;
    }
    if (formData.delegate) {
      formData.delegate = formData.delegate._id;
    }
    if (formData.lead_status.length > 0) {
      formData.lead_status = formData.lead_status.map((status) => status._id);
    }
    if (formData.community?.length > 0) {
      formData.community = formData.community.map((level) => level.name);
    }
    if (formData.sort_by) {
      formData.sort_by = formData.sort_by.chip_value;
    }
    if (formData.is_date_range) {
      if (formData.from_date) {
        formData.from_date = api_date_format(formData.from_date);
      }
      if (formData.to_date) {
        formData.to_date = api_date_format(formData.to_date);
      }
    }
    if (formData.member_ship_expiry == "not_expired") {
      formData.membership_purchase_expiry_from = api_date_format(
        formData.membership_purchase_expiry_from
      );
      formData.membership_purchase_expiry_to = api_date_format(
        formData.membership_purchase_expiry_to
      );
      if (formData.expiry_in !== "custom") {
        formData.membership_purchase_expiry_to = moment(
          formData.membership_purchase_expiry_from,
          "YYYY-MM-DD"
        )
          .add(formData.expiry_in, "days")
          .format("YYYY-MM-DD");
      }
    }
    const result = await allMemberListingForDelegate(
      formData.page ? formData.page : page,
      formData.RowsNumber ? formData.RowsNumber : rowsPerPage,
      formData.search_text,
      formData
    );
    if (result.code == 200) {
      const members = result.member?.map((member, index) => {
        let call_history = {
          is_checked: false,
        };
        if (member.call_history) {
          call_history = member.call_history;
        }
        let membership_expire = member?.membership_purchase_expiry
          ? member?.membership_purchase_expiry
          : "N/A";

        //Getting Referred User
        let affiliate_name = "Master Link";
        let affiliate_info = member.affliliate?.affiliate_user_info;
        if (affiliate_info) {
          affiliate_name =
            affiliate_info.first_name + " " + affiliate_info.last_name;
        }
        if (member.affliliate?.affiliate_url_name) {
          //Attach affiliate_url_name
          affiliate_name =
            affiliate_name + " (" + member.affliliate.affiliate_url_name + ")";
        }

        //attach marketing affliate

        let marketing_affliliate_url_name;
        if (member?.marketing_affliliate?.affiliate_url_name) {
          marketing_affliliate_url_name =
            member.marketing_affliliate.affiliate_url_name;
        }
        let market_affiliate_name = "N/A";
        let market_affiliate_info =
          member.marketing_affliliate?.affiliate_user_info;

        if (market_affiliate_info) {
          market_affiliate_name =
            market_affiliate_info.first_name +
            " " +
            market_affiliate_info.last_name;
          if (member.affliliate?.affiliate_url_name && affiliate_info) {
            market_affiliate_name =
              market_affiliate_name +
              " (" +
              marketing_affliliate_url_name +
              " | " +
              "Sales Team" +
              ")";
          }
        }

        // Getting Delegate name
        let delegate_name = "N/A";
        let delegate = member.consultant;
        if (delegate) {
          delegate_name = delegate.first_name + " " + delegate.last_name;
        }

        let goal_statement_status = member.goal_statement_completed_status;
        let goal_statement_text = "Incomplete";
        if (goal_statement_status && member.save_and_close_status) {
          goal_statement_text =
            "Responded" +
            " (" +
            dd_date_format(member.save_and_close_date) +
            ")";
        } else if (goal_statement_status && !member.save_and_close_status) {
          goal_statement_text =
            "Completed" +
            " (" +
            dd_date_format(member.goal_statement_completed_date) +
            ")";
        }

        let wheel_of_life_enable = "No";
        if (member.is_wheel_of_life_enable) {
          wheel_of_life_enable = "Yes";
        }

        return {
          ...member,
          name: member.first_name + " " + member.last_name,
          id: member._id,
          affiliate_name: affiliate_name,
          market_affiliate_name,
          delegate_name: delegate_name,
          nurture: member.nurture
            ? member.nurture.first_name + " " + member.nurture.last_name
            : "N/A",
          completeNurture: member.nurture,
          goal_statement_text: goal_statement_text,
          created_at: dd_date_format(member.createdAt),
          user_coins: get_kmb_number(member.coins_count),
          pages: member.event_subscriber,
          diary_coins_count: get_kmb_number(member.dynamite_diary_coins_count),
          meditation_coins: get_kmb_number(member.meditation_coins_count),
          wheel_of_life_enable: wheel_of_life_enable,
          lead_status: member.lead_status,
          assessment_coins_count: get_kmb_number(
            member.attitude_assessment_coins_count
          ),
          gratitude_coins: get_kmb_number(
            member.dynamite_gratitude_coins_count
          ),
          membership_expire: membership_expire,
          phone: member.contact_number ? member.contact_number : "N/A",
          avatarUrl: member.profile_image,
          last_login_activity: member.last_login_activity
            ? get_start_date_end_time(member.last_login_activity)
            : "N/A",
          table_avatar: {
            src: member.profile_image ? s3baseUrl + member.profile_image : "/",
            alt: member.first_name,
          },
          call_history,
        };
      });
      handle_chips_data(flterData, formData);
      setMembersData(members);
      setTotalCount(result.total_member_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleAllowCall = async () => {
    setOpenAllowCall(false);
    let postData = {
      is_call_allowed: !Boolean(selectedObject.is_call_allowed),
      member_id: selectedObject._id,
    };

    setMembersData((prev) =>
      prev.map((user) => {
        if (user._id === selectedObject._id) {
          user.is_call_allowed = postData.is_call_allowed;
        }
        return user;
      })
    );
    const result = await update_member_call_alowed_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "user_coins",
      label: "Coins",
    },
    {
      id: "downloaded_app",
      label: "Downloaded App",

      renderData: (row) => {
        return (
          <span>
            <Tooltip title={row.downloaded_app ? "Downloaded" : "Not Yet !"}>
              <span>
                {row.downloaded_app ? (
                  <img
                    src={downloadIcon}
                    alt="download"
                    className="download-icon-new"
                  />
                ) : (
                  <img
                    src={notDownloadIcon}
                    alt="not-download"
                    className="download-icon-new"
                  />
                )}
              </span>
            </Tooltip>
          </span>
        );
      },
    },
    {
      id: "is_wheel_of_life",
      label: "Wheel Of Life",
      renderData: (row) => {
        if (row.is_wheel_of_life) {
          return (
            <Tooltip title={"Wheel of life is completed"} arrow>
              <img src={wheelIcon} className="wheel-of-life-icon" />
            </Tooltip>
          );
        }
      },
    },
    {
      id: "name",
      label: "Name",
      renderData: (row) => {
        let country;
        if (row.country) {
          country = row.country.toLowerCase();
        }
        return (
          <div className="d-flex" style={{ alignItems: "center" }}>
            {isAccess.is_chat_allowed && (
              <Tooltip title={"Chat"} arrow>
                <MessageIcon
                  className="anchor-style"
                  onClick={() => handleSendMessage(row._id)}
                />
              </Tooltip>
            )}
            <div
              className={`ms-2 ${consultant_both ? "anchor-style" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                if (consultant_both) {
                  handleDetail(row);
                }
              }}
            >
              {row.name}
            </div>
            {country && (
              <img
                className="flag"
                src={`https://flagpedia.net/data/flags/mini/${country}.png`}
              />
            )}
          </div>
        );
      },
    },
    {
      id: "profile_image",
      label: "Profile",
      renderData: (row) => {
        return (
          <Tooltip title={row.is_online === false ? "Offline" : "Online"}>
            <span
              className={`position-relative ${
                consultant_both ? "anchor-style" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                if (consultant_both) {
                  handleDetail(row);
                }
              }}
            >
              <CircleIcon
                className={`${
                  row.is_online === false ? "position" : "position-online"
                }`}
              />
              <Avatar alt={row.name} src={s3baseUrl + row.profile_image} />
            </span>
          </Tooltip>
        );
      },
    },
    { id: "email", label: "Email" },
    {
      id: "last_login_activity",
      label: "Last Login Activity",
      alignRight: false,
    },
    {
      id: "contact_number",
      label: "Phone Number",
      renderData: (row, index) => {
        return (
          <>
            <span>
              <Tooltip
                title={
                  !row.is_call_allowed ? (
                    "Do not make a call to this member."
                  ) : row.call_history?.notes ? (
                    <div
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: row.call_history?.notes,
                        }}
                      ></span>
                      <p className="text-end note-date mt-1">
                        {dd_date_format(row.call_history?.date)}
                      </p>
                      <hr />
                    </div>
                  ) : (
                    "click here to add call history note!"
                  )
                }
              >
                <div className="d-flex align-items-center">
                  <div>
                    {row.is_call_allowed ? (
                      <Checkbox
                        checked={Boolean(row.call_history.is_checked)}
                        className="p-1"
                        onChange={
                          row.call_history.is_checked
                            ? ""
                            : (e) => handleInputChange(e, index, row)
                        }
                      />
                    ) : (
                      <div className="call-not-allowed">
                        <CloseIcon />
                      </div>
                    )}
                  </div>
                  <div>{row.contact_number}</div>
                </div>
              </Tooltip>
            </span>
          </>
        );
      },
    },
    {
      id: "lead_status",
      label: "Lead Status",
      renderData: (row, index) => {
        let lead_status = row.lead_status;
        let lead_status_history = row.lead_status_history;

        return (
          <div className="position-relative">
            {lead_status && Object.keys(lead_status).length > 0 && (
              <span
                className="me-1"
                style={{ cursor: "pointer" }}
                onClick={() => change_lead_status_history(row)}
              >
                <Tooltip title="View History">
                  <HistoryIcon />
                </Tooltip>
              </span>
            )}

            {openDiv == index ? (
              <ChangeLeadStatusForList
                selectedObject={row}
                setInputsUpdated={setInputsUpdated}
                handleChangeLeadStatus={handleChangeLeadStatus}
                rowData={row}
                leadStatusOld={leadStatusOld}
                consultant_both={consultant_both}
                setleadStatusOld={setleadStatusOld}
                leadStatus={leadStatus}
                open={Boolean(openDiv == index)}
                setOpenDiv={setOpenDiv}
              />
            ) : (
              <div
                className="fake_lead_status pointer position-relative"
                onClick={() => setOpenDiv(index)}
                style={{
                  backgroundColor: lead_status?.background_color,
                  color: lead_status?.text_color,
                }}
              >
                <span
                  style={{
                    color: lead_status?.text_color
                      ? lead_status?.text_color
                      : "gray",
                  }}
                >
                  {get_short_string(
                    lead_status?.title ? lead_status?.title : "Lead Status",
                    20
                  )}
                </span>
                <span className="icon-arrow-down">
                  <ArrowDropDownIcon />
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      id: "Client_note",
      label: "Client Note",
      renderData: (row) => <PersonalNotes client_note={row.personal_note} />,
    },
    { id: "affiliate_name", label: "Referred User" },
    { id: "market_affiliate_name", label: "Marketing Referred User" },

    { id: "delegate_name", label: "Delegate" },
    {
      id: "event_subscriber",
      label: "Pages",
      renderData: (row) => (
        <PagesList
          pages={row.event_subscriber}
          handleClick={handleClickPopUPPages}
        />
      ),
    },
    {
      id: "program",
      label: "Programmes",
      renderData: (row) => (
        <ProgramsList programs={row.program} handleClick={handleClickPopUP} />
      ),
    },
    {
      label: "Community Level",
      className: "text-capitalize",
      renderData: (row) => {
        if (row.community_level) {
          let find_level = COMMUNITY_LEVELS.find(
            (level) => level?.name == row.community_level
          );
          return find_level.title;
        }
      },
    },
    {
      id: "wheel_of_life_enable",
      label: "Wheel Of Life Enable",
      className: "text-capitalize",
    },

    {
      id: "diary_coins_count",
      label: "Daily Intention Coins",
    },
    {
      id: "gratitude_coins",
      label: "Gratitude Coins",
    },
    {
      id: "assessment_coins_count",
      label: "Assessment Coins",
    },
    {
      id: "meditation_coins",
      label: "Meditation Coins",
    },
    {
      id: "goal_statement_text",
      label: "Goal Statement",
    },
    {
      id: "created_at",
      label: "Created At",
    },
    {
      id: "membership_expire",
      label: "Membership Expire",
    },
    { id: "status", label: "Status", type: "row_status" },
    {
      label: "Goal",
      renderData: (row) => {
        return (
          <Chip
            width="180px"
            label={row.goal_statement_status === true ? "Unlocked" : "Locked"}
            variant="contained"
            className={
              row.goal_statement_status === true
                ? `manage-program-chip-success`
                : ""
            }
            color={row.goal_statement_status === true ? "success" : "error"}
            size="small"
          />
        );
      },
    },
  ];
  if (isAccess.Show_nurture_in_filter) {
    TABLE_HEAD.splice(13, 0, { id: "nurture", label: "Nurture" });
  }
  const handleInputChange = (e, index, object) => {
    const list = [...membersData];
    list[index]["call_history"]["is_checked"] = true;
    setMembersData(list);
    setMemberObject(object);
    setOpenCallType(true);
    setindex(index);
  };
  const handleSendMessage = async (id) => {
    handleAddChat(id);
  };
  const handleSave = (event, name) => {
    setSaveFilter((values) => ({ ...values, [name]: event }));
  };
  const handleExistingFilter = (event) => {
    setIschangeFilter(false);
    if (event == null || SaveFilter.filter_From == "new") {
      setFilterData(EMPTY_FILTER);
      setSaveFilter((values) => ({ ...values, ["filter_name"]: null }));
    } else {
      setFilterData(event.filter_object);
    }
  };
  const handleChangeSave = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSaveFilter((values) => ({ ...values, [name]: value }));
    if (value == "new") {
      setSaveFilter((values) => ({ ...values, ["filter_name"]: null }));
      setFilterData(EMPTY_FILTER);
    }
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getMembersList(filterData);
    localStorage.setItem("all_member_filter_data", JSON.stringify(filterData));
    localStorage.setItem("all-member_save", JSON.stringify(SaveFilter));
    setfinal(ischangeFilter);
    handleCloseFilterDrawer();
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    if (!data.date) {
      delete data.date;
      data.from_date = null;
      data.is_date_range = false;
      data.to_date = null;
    }
    if (!data.coins) {
      delete data.coins;
      data.coins_from = 0;
      data.coins_range = false;
      data.coins_to = 0;
    }
    if (!data.membership_expiry) {
      delete data.membership_expiry;
      data.membership_purchase_expiry_from = new Date();
      data.membership_purchase_expiry_to = new Date();
      data.expiry_in = 3;
      data.member_ship_expiry = "";
    }
    setfinal(true);
    getMembersList(data);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("all-member-row", event.target.value);
    localStorage.setItem("all_member_page_number", 0);
    setPageCount(1);
    setPage(0);
  };

  const handleQuestionsAnswers = (value) => {
    navigate(`/all-members/questions-answers?member_id=${value._id}`, {
      state: value,
    });
  };

  const handleDetail = (value) => {
    navigate(`/all-members/profile/${value._id}`, {
      state: value,
    });
  };

  const handlePersonelNotes = (value) => {
    navigate(`/all-members/personel-notes/${value._id}`, {
      state: value,
    });
  };

  const handleSubscriber = (value) => {
    navigate(`/all-members/subscribers/${value._id}`, {
      state: value,
    });
  };

  const handleUpdateStatus = (value, lead_status_history) => {
    let index = membersData.findIndex((user) => user._id == leadStatusOld._id);
    let temp_state = [...membersData];
    let temp_element = { ...temp_state[index] };
    temp_element.lead_status = value;
    temp_element.lead_status_history = lead_status_history;
    temp_state[index] = temp_element;
    setMembersData(temp_state);
    handleCloseLeadStatus();
  };

  const handleUpdateAllowCall = (value) => {
    setSelectedObject(value);
    setOpenAllowCall(true);
  };

  const MENU_OPTIONS = [
    // {
    //   label: "Change Lead Status",
    //   icon: "eva:eye-fill",
    //   handleClick: change_lead_status,
    // },
    {
      label: "View Subscriptions",
      icon: "eva:eye-fill",
      handleClick: handleSubscriber,
    },
    {
      label: "Personal Notes",
      icon: "gridicons:pages",
      handleClick: handlePersonelNotes,
    },
    {
      label: "Update Call Functionality",
      icon: "material-symbols-light:call-sharp",
      handleClick: handleUpdateAllowCall,
    },
    {
      label: "Questions Answers",
      icon: "ant-design:lock",
      handleClick: handleQuestionsAnswers,
    },
  ];

  if (consultant_both) {
    MENU_OPTIONS.splice(2, 0, {
      label: "View Profile",
      icon: "eva:eye-fill",
      handleClick: handleDetail,
    });
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
    localStorage.setItem("all_member_page_number", newPage);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const get_active_lead_status = async () => {
    const result = await active_lead_status_api();
    if (result.code === 200) {
      setLeadStatus(result.lead_status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    get_active_lead_status();
  }, []);

  useEffect(() => {
    let PageFromLocalStorage = localStorage.getItem("all_member_page_number");
    let rowsFromLocalStorage = localStorage.getItem("all-member-row");
    const pageNumber = Number(PageFromLocalStorage) || 1;
    const RowsNumber = Number(rowsFromLocalStorage) || 50;
    setRowsPerPage(RowsNumber);
    setPage(PageFromLocalStorage <= 0 ? 0 : PageFromLocalStorage - 1);
    setPageCount(pageNumber <= 0 ? 1 : pageNumber);
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("all_member_filter_data");
    let find_filter_SAVE = localStorage.getItem("all-member_save");
    let filter_data_save;
    if (find_filter_SAVE) {
      filter_data_save = JSON.parse(find_filter_SAVE);
      if (filter_data_save) {
        setSaveFilter(filter_data_save);
      }
    }
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    if (PageFromLocalStorage) {
      filter_data.page =
        PageFromLocalStorage <= 0 ? 0 : PageFromLocalStorage - 1;
    }
    if (RowsNumber) {
      filter_data.RowsNumber = RowsNumber;
    }
    getMembersList(filter_data);
  }, [rowsPerPage, page]);

  useEffect(() => {
    setMembersData((old) =>
      old.map((member) => {
        if (member._id == onlineOfflineData.user_id) {
          member.is_online = onlineOfflineData.is_online;
        }
        return member;
      })
    );
  }, [onlineOfflineData]);
  useEffect(() => {
    if (!openCallType) {
      if (index) {
        const list = [...membersData];
        list[index]["call_history"]["is_checked"] = false;
        setMembersData(list);
      }
    }
  }, [openCallType]);

  const headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Contact Number", key: "contact_number" },
  ];

  const handleExportClick = () => {
    download_csv_file(headers, membersData);
  };

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <div className="col-6">
            <h2>All Members</h2>
          </div>
          <div className="col-6 d-flex pe-0">
            <MUIAutocomplete
              inputLabel="Sort By"
              size="small"
              selectedOption={filterData.sort_by}
              setSelectedOption={(e) => {
                handleChangeSort(e, "sort_by");
              }}
              optionsList={sort_by_array}
              name="chip_label"
            />
            <button
              className="small-contained-button ms-2  mb-4 "
              onClick={handleOpenFilterDrawer}
              style={{ width: "175px" }}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>{" "}
            {/* {userInfo.is_super_delegate && ( */}
            {isAccess.all_member_export_csv && (
              <button
                className="small-contained-button ms-2  mb-4 "
                onClick={handleExportClick}
                style={{ width: "185px" }}
              >
                Export Csv
              </button>
            )}
          </div>
          <div
            className="col-3 ps-0 pe-0 d-flex"
            style={{ textAlign: "center" }}
          ></div>
        </div>
      </div>
      <div className="row mb-2">
        <FilteredChip
          data={formDataUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          REMOVE_ITEMS={REMOVE_ITEMS}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
          tabName={final ? "all-member" : ""}
        />
      </div>
      <div className="col-12">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={membersData}
          selected={selected}
          MENU_OPTIONS={MENU_OPTIONS}
          setSelected={setSelected}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filter Pages"
        componentToPassDown={
          <MemberFilter
            filterData={filterData}
            handleChange={handleChange}
            setFilterData={setFilterData}
            handleChangeOthers={handleChangeOthers}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            is_delegate_filter={true}
            is_nurture_filter={true}
            handleIsCountry={handleIsCountry}
            consultant_both={isAccess.Show_nurture_in_filter}
            handleExistingFilter={handleExistingFilter}
            SaveFilter={SaveFilter}
            handleChangeSave={handleChangeSave}
            handleSave={handleSave}
            filter_on_tab_name={"all-member"}
          />
        }
      />

      <GeneralPopOver
        handleClose={handleClosePopUp}
        anchorEl={anchorElProgram}
        open={open_program_popover}
        popover_id={program_popover_id}
        title="Programs"
        component={<ProgramsList programs={dataList} show_full={true} />}
      />
      <GeneralPopOver
        handleClose={handleClosePopUpPages}
        anchorEl={anchorEl}
        open={open_pages_popover}
        popover_id={pages_popover_id}
        title="Pages"
        component={<PagesList pages={dataList} show_full={true} />}
      />
      <WhatsappPopUpModel
        open={changeStatusOpen}
        setOpen={setChangeStatusOpen}
        title={"Lead Status History"}
        show_date_and_income={true}
        componentToPassDown={
          <LeadStatusHistory
            setOpen={setChangeStatusOpen}
            history={history}
            leadStatusData={leadStatusData}
          />
        }
      />

      <GeneralPopUpModel
        open={openCallType}
        setOpen={setOpenCallType}
        title={"Call History Note"}
        componentToPassDown={
          <CallTypeNotes
            setOpen={setOpenCallType}
            memberObject={memberObject}
            getMembersList={getMembersList}
            filterData={filterData}
            setindex={setindex}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={changeStatus}
        onOpenDrawer={handleOpenLeadStatus}
        onCloseDrawer={handleCrossedLeadStatis}
        pageTitle={"Change Lead Status"}
        componentToPassDown={
          <ChangeLeadStatus
            selectedObject={selectedMember}
            leadStatus={leadStatus}
            handleUpdateStatus={handleUpdateStatus}
          />
        }
      />
      <CustomConfirmation
        open={openAllowCall}
        setOpen={setOpenAllowCall}
        title={`Are you sure you want to ${
          selectedObject?.is_call_allowed ? "disable" : "enable"
        } call functionality for this user?`}
        handleAgree={handleAllowCall}
      />
    </>
  );
}
