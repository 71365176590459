import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { update_member_call_history_api } from "src/DAL/Booking/bookingApi";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import moment from "moment";

export default function CallTypeNotes({
  setOpen,
  memberObject,
  getMembersList,
  filterData,
  setindex,
}) {
  const [inputs, setInputs] = useState({
    note: "",
    add_as_personal_note: false,
    date: new Date(),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeStatus = async () => {
    if (inputs.note.length <= 0) {
      enqueueSnackbar("Note is Required", { variant: "error" });
      setOpenStatus(false);
    } else {
      setOpenStatus(false);
      setIsLoading(true);
      const postData = {
        is_checked: true,
        date: moment(inputs.date).format("DD-MM-YYYY"),
        notes: inputs.note,
        is_add_to_personal_notes: inputs.add_as_personal_note,
      };
      const result = await update_member_call_history_api(
        memberObject._id,
        postData
      );
      if (result.code === 200) {
        getMembersList(filterData ? filterData : result.call_history);
        setOpen(false);
        setIsLoading(false);
        if (setindex) {
          setindex(null);
        }
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };
  const handleChangeDate = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenStatus(true);
  };

  return (
    <div className="container">
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          {" "}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              name="date"
              format="YYYY-MM-DD"
              inputFormat="dd-MM-yyyy"
              value={inputs.date}
              onChange={(e) => {
                handleChangeDate("date", e);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="inputs-fields"
                  required={true}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 mt-3">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Would you like to add it to Personal Notes?
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="add_as_personal_note"
              value={inputs.add_as_personal_note}
              label="Would you like to add it to Personal Notes?"
              onChange={(event) =>
                handleChangeOthers("add_as_personal_note", event.target.value)
              }
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 ">
          <p className="tracking_code_heading text-white">Note* </p>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="note"
            editorHeight={320}
          />
        </div>
        <CustomConfirmation
          open={openStatus}
          setOpen={setOpenStatus}
          title={"Are you sure you want to  add call history note?"}
          handleAgree={handleChangeStatus}
        />
        <div class="col-12 mt-3">
          <button class="small-contained-button w-100">
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}
