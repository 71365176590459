import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  CircularProgress,
  Chip,
  Tooltip,
  Avatar,
  FormControlLabel,
  Checkbox,
  Toolbar,
} from "@mui/material";
import { subscriber_list_for_member_with_filter } from "src/DAL/member/Member";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";

import {
  COMMUNITY_LEVELS,
  TIME_ZONE_CONVERSION,
  TIME_ZONE_CONVERSION_new,
  api_date_format,
  dd_date_format,
  get_kmb_number,
  get_short_string,
} from "src/utils/constants";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import MessageIcon from "@mui/icons-material/Message";
import { downloadIcon, notDownloadIcon, wheelIcon } from "src/assets";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import MemberPagesPopOver from "../../components/GeneralComponents/GeneralPopOver";
import ChangeLeadStatus from "../../components/MembersList/ChangeLeadStatus";
import PersonalNotes from "src/components/MembersList/PersonalNotes";
import PagesList from "src/components/MembersList/PagesList";
import FilteredChip from "src/components/GeneralComponents/FilteredChip";
import MemberFilter from "src/components/MembersList/MemberFilter";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect } from "react";
import { active_lead_status_api } from "src/DAL/leadStatus/leadStatus";
import ChangeLeadStatusForList from "src/components/MembersList/ChangeLeadStatusForList";
import LeadStatusHistory from "./LeadStatusHistory";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import HistoryIcon from "@mui/icons-material/History";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import CustomPopover from "src/components/CustomPopover";
import { download_csv_file, urlify } from "src/utils/convertHtml";
import CallTypeNotes from "./CallTypeNotes";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { update_member_call_alowed_api } from "src/DAL/LeadsCenter/lead_stages";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const sort_by_array = [
  {
    chip_label: "Registration Date (Oldest First)",
    chip_value: "registration_date_asc",
  },
  {
    chip_label: "Registration Date (Newest First)",
    chip_value: "registration_date_desc",
  },
  {
    chip_label: "Membership Expiry Date (Oldest First)",
    chip_value: "membership_expiry_date_asc",
  },
  {
    chip_label: "Membership Expiry Date (Newest First)",
    chip_value: "membership_expiry_date_desc",
  },
  {
    chip_label: "Last Login Date (Oldest First)",
    chip_value: "last_login_date_asc",
  },
  {
    chip_label: "Last Login Date (Newest First)",
    chip_value: "last_login_date_desc",
  },
];
const EMPTY_FILTER = {
  community: [],
  event_page: null,
  lead_status: [],
  sort_by: {
    chip_label: "Registration Date (Newest First)",
    chip_value: "registration_date_desc",
  },
  plan: null,
  nurture: null,
  is_date_range: false,
  coins_range: false,
  coins_from: 0,
  coins_to: 0,
  from_date: null,
  to_date: null,
  membership_purchase_expiry_from: new Date(),
  membership_purchase_expiry_to: new Date(),
  date: null,
  coins: null,
  membership_expiry: null,
  status: "",
  expiry_in: 3,
  member_user_type: "",
  member_ship_expiry: "",
  user_status_type: "",
  filter_name: null,
  filter_From: "",
  country: null,
  downloaded_app: null,
};

const REMOVE_ITEMS = ["RowsNumber"];

export default function MembersList() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [openDiv, setOpenDiv] = useState(null);
  const classes = useStyles();
  const { userInfo, adminTimeZone, isAccess } = useContentSetting();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [membersData, setMembersData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const { handleAddChat, onlineOfflineData } = useChat();
  const [changeStatus, setChangeStatus] = useState(false);
  const [openCallType, setOpenCallType] = useState(false);
  const [index, setindex] = useState(null);
  const [selectedMember, setSelectedMember] = useState({});
  const [ischangeFilter, setIschangeFilter] = useState(false);
  const [final, setfinal] = useState(false);
  const [anchorElPages, setAnchorElPages] = useState(null);
  const [Pages, setPages] = useState([]);
  const [leadStatusData, setleadStatusData] = useState({});
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [SaveFilter, setSaveFilter] = useState({
    filter_name: null,
    filter_From: "new",
  });
  const [memberObject, setMemberObject] = useState({});
  const [formDataUpdated, setformDataUpdated] = useState(EMPTY_FILTER);
  const [leadStatus, setLeadStatus] = useState([]);
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [inputsUpdated, setInputsUpdated] = useState({ lead_status: null });
  const [leadStatusOld, setleadStatusOld] = useState({});
  const [openAllowCall, setOpenAllowCall] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [history, sethistory] = useState([]);
  const openPages = Boolean(anchorElPages);
  const id_pages = "simple-popover-pages";

  let consultant_both = false;
  if (isAccess.view_profile) {
    consultant_both = true;
  }
  const change_lead_status_history = (value) => {
    setleadStatusData(value);
    setChangeStatusOpen(true);
  };
  const handleChangeLeadStatus = (value, row) => {
    setMembersData((old) => {
      return old.map((item) => {
        if (item._id == row._id) {
          return {
            ...item,
            lead_status: value,
          };
        }
        return item;
      });
    });
    setleadStatusOld(row);
    change_lead_status({
      ...row,
      lead_status: value,
    });
  };
  const handleCrossedLeadStatis = () => {
    handleUpdateStatus(
      leadStatusOld.lead_status,
      leadStatusOld.lead_status_history
    );
  };

  const handleInputChange = (e, index, object) => {
    const list = [...membersData];
    list[index]["call_history"]["is_checked"] = true;
    setMembersData(list);
    setMemberObject(object);
    setOpenCallType(true);
    setindex(index);
  };

  const handleAllowCall = async () => {
    setOpenAllowCall(false);
    let postData = {
      is_call_allowed: !Boolean(selectedObject.is_call_allowed),
      member_id: selectedObject._id,
    };

    setMembersData((prev) =>
      prev.map((user) => {
        if (user._id === selectedObject._id) {
          user.is_call_allowed = postData.is_call_allowed;
        }
        return user;
      })
    );
    const result = await update_member_call_alowed_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "user_coins", label: "Coins" },
    {
      id: "downloaded_app",
      label: "Downloaded App",

      renderData: (row) => {
        return (
          <span>
            <Tooltip title={row.downloaded_app ? "Downloaded" : "Not Yet !"}>
              <span>
                {row.downloaded_app ? (
                  <img
                    src={downloadIcon}
                    alt="download"
                    className="download-icon-new"
                  />
                ) : (
                  <img
                    src={notDownloadIcon}
                    alt="not-download"
                    className="download-icon-new"
                  />
                )}
              </span>
            </Tooltip>
          </span>
        );
      },
    },
    {
      id: "is_wheel_of_life",
      label: "Wheel Of Life",
      renderData: (row) => {
        if (row.is_wheel_of_life) {
          return (
            <Tooltip title={"Wheel of life is completed"} arrow>
              <img src={wheelIcon} className="wheel-of-life-icon" />
            </Tooltip>
          );
        }
      },
    },
    {
      id: "name",
      label: "Name",
      renderData: (row) => {
        let country;
        if (row.country) {
          country = row.country.toLowerCase();
        }
        return (
          <div className="d-flex" style={{ alignItems: "center" }}>
            {isAccess.is_chat_allowed && (
              <Tooltip title={"Chat"} arrow>
                <MessageIcon
                  className="anchor-style"
                  onClick={() => handleSendMessage(row._id)}
                />
              </Tooltip>
            )}
            <div
              className={`ms-2 ${consultant_both ? "anchor-style" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                if (consultant_both) {
                  handleDetail(row);
                }
              }}
            >
              {row.name}
            </div>{" "}
            {country && (
              <img
                className="flag"
                src={`https://flagpedia.net/data/flags/mini/${country}.png`}
              />
            )}
          </div>
        );
      },
    },
    {
      id: "profile_image",
      label: "Profile",
      renderData: (row) => {
        return (
          <Tooltip title={row.is_online === false ? "Offline" : "Online"}>
            <span
              className={`position-relative ${
                consultant_both ? "anchor-style" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                if (consultant_both) {
                  handleDetail(row);
                }
              }}
            >
              <CircleIcon
                className={`${
                  row.is_online === false ? "position" : "position-online"
                }`}
              />
              <Avatar alt={row.name} src={s3baseUrl + row.profile_image} />
            </span>
          </Tooltip>
        );
      },
    },
    {
      id: "last_login_activity",
      label: "Last Login Activity",
      alignRight: false,
    },
    {
      id: "contact_number",
      label: "Phone Number",
      renderData: (row, index) => {
        return (
          <>
            <span>
              <Tooltip
                title={
                  !row.is_call_allowed ? (
                    "Do not make a call to this member."
                  ) : row.call_history?.notes ? (
                    <div
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: row.call_history?.notes,
                        }}
                      ></span>
                      <p className="text-end note-date mt-1">
                        {dd_date_format(row.call_history?.date)}
                      </p>
                      <hr />
                    </div>
                  ) : (
                    "click here to add call history note!"
                  )
                }
              >
                <div className="d-flex align-items-center">
                  <div>
                    {row.is_call_allowed ? (
                      <Checkbox
                        checked={Boolean(row.call_history.is_checked)}
                        className="p-1"
                        onChange={
                          row.call_history.is_checked
                            ? ""
                            : (e) => handleInputChange(e, index, row)
                        }
                      />
                    ) : (
                      <div className="call-not-allowed">
                        <CloseIcon />
                      </div>
                    )}
                  </div>
                  <div>{row.contact_number}</div>
                </div>
              </Tooltip>
            </span>
          </>
        );
      },
    },
    {
      id: "lead_status",
      label: "Lead Status",
      renderData: (row, index) => {
        let lead_status = row.lead_status;
        let lead_status_history = row.lead_status_history;

        return (
          <div className="position-relative">
            {lead_status && Object.keys(lead_status).length > 0 && (
              <span
                className="me-1"
                style={{ cursor: "pointer" }}
                onClick={() => change_lead_status_history(row)}
              >
                <Tooltip title="View History">
                  <HistoryIcon />
                </Tooltip>
              </span>
            )}

            {openDiv == index ? (
              <ChangeLeadStatusForList
                selectedObject={row}
                setInputsUpdated={setInputsUpdated}
                handleChangeLeadStatus={handleChangeLeadStatus}
                rowData={row}
                leadStatusOld={leadStatusOld}
                consultant_both={consultant_both}
                setleadStatusOld={setleadStatusOld}
                leadStatus={leadStatus}
                open={Boolean(openDiv == index)}
                setOpenDiv={setOpenDiv}
              />
            ) : (
              <div
                className="fake_lead_status pointer position-relative"
                onClick={() => setOpenDiv(index)}
                style={{
                  backgroundColor: lead_status?.background_color,
                  color: lead_status?.text_color,
                }}
              >
                <span
                  style={{
                    color: lead_status?.text_color
                      ? lead_status?.text_color
                      : "gray",
                  }}
                >
                  {get_short_string(
                    lead_status?.title ? lead_status?.title : "Lead Status",
                    20
                  )}
                </span>
                <span className="icon-arrow-down">
                  <ArrowDropDownIcon />
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      id: "Client_note",
      label: "Client Note",
      renderData: (row) => {
        const client_note = row.personal_note;
        return <PersonalNotes client_note={client_note} />;
      },
    },
    {
      id: "event_subscriber",
      label: "Pages",
      renderData: (row) => {
        const pages = row.event_subscriber;
        return <PagesList pages={pages} handleClick={handleClickPages} />;
      },
    },

    {
      label: "Community Level",
      className: "text-capitalize",
      renderData: (row) => {
        if (row.community_level) {
          let find_level = COMMUNITY_LEVELS.find(
            (level) => level?.name == row.community_level
          );
          return find_level.title;
        }
      },
    },
    { id: "created_at", label: "Registration Date" },
    {
      id: "membership_expire",
      label: "Membership Expire",
    },

    { id: "status", label: "Status", type: "row_status" },
  ];

  TABLE_HEAD.splice(0, 0, {
    id: "action",
    label: "Action",
    type: "action",
    MENU_OPTIONS: "MENU_OPTIONS",
  });

  if (consultant_both) {
    TABLE_HEAD.splice(9, 0, {
      id: "completed_date",
      label: "Wheel Of Life Completed Date",
    });
  }
  if (isAccess.Show_nurture_in_filter) {
    TABLE_HEAD.splice(12, 0, { id: "nurture_name", label: "Nurture" });
  }

  const handle_chips_data = (flterData, formData) => {
    let chipData = { ...flterData };
    delete chipData.page;
    if (chipData.is_date_range) {
      let date = `Start Date : ${dd_date_format(
        chipData.from_date
      )} - End Date :  ${dd_date_format(chipData.to_date)}`;
      chipData.date = {
        chip_label: date,
        chip_value: date,
      };
    }
    if (chipData.coins_range) {
      let coins = `Start Coins : ${chipData.coins_from} - End Coins :  ${chipData.coins_to}`;
      chipData.coins = {
        chip_label: coins,
        chip_value: coins,
      };
    }
    if (chipData.member_ship_expiry == "not_expired") {
      let membership_date = "";
      if (chipData.expiry_in !== "custom") {
        membership_date = `Expiry in ${chipData.expiry_in} days`;
      } else {
        membership_date = `Membership Expiry Start Date : ${dd_date_format(
          chipData.membership_purchase_expiry_from
        )} - Membership Expiry End Date :  ${moment(
          formData.membership_purchase_expiry_to,
          "YYYY-MM-DD"
        ).format("DD-MM-YYYY")}`;
      }

      chipData.membership_expiry = {
        chip_label: membership_date,
        chip_value: membership_date,
      };
    }

    setFilterData({ ...chipData });
    localStorage.setItem("member_filter_data", JSON.stringify({ ...chipData }));

    if (chipData.member_ship_expiry == "not_expired") {
      delete chipData.member_ship_expiry; //not show not_expired in chip
    }

    //not show these variables in chip
    delete chipData.from_date;
    delete chipData.to_date;
    delete chipData.coins_from;
    delete chipData.coins_to;
    delete chipData.membership_purchase_expiry_from;
    delete chipData.membership_purchase_expiry_to;
    delete chipData.expiry_in;
    delete chipData.search_text;
    setformDataUpdated(chipData);
  };

  const get_start_date_end_time = (date) => {
    return TIME_ZONE_CONVERSION_new(
      date,
      "DD-MM-YYYY hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  const getMembersList = async (flterData) => {
    setIsLoading(true);
    let formData = { ...flterData };
    if (formData.downloaded_app) {
      formData.downloaded_app = formData.downloaded_app.value;
    }
    if (formData.country) {
      formData.country = formData.country.code;
    }
    formData.coins_from = +formData.coins_from;
    formData.coins_to = +formData.coins_to;
    if (formData.event_page) {
      formData.event_page = [formData.event_page._id];
    } else {
      formData.event_page = [];
    }
    if (formData.plan) {
      formData.plan = formData.plan._id;
    }
    if (formData.nurture) {
      formData.nurture = formData.nurture._id;
    }
    if (formData.lead_status.length > 0) {
      formData.lead_status = formData.lead_status.map((status) => status._id);
    }
    if (formData.community.length > 0) {
      formData.community = formData.community.map((level) => level.name);
    }
    if (formData.sort_by) {
      formData.sort_by = formData.sort_by.chip_value;
    }
    if (formData.is_date_range) {
      if (formData.from_date) {
        formData.from_date = api_date_format(formData.from_date);
      }
      if (formData.to_date) {
        formData.to_date = api_date_format(formData.to_date);
      }
    }
    if (formData.member_ship_expiry == "not_expired") {
      formData.membership_purchase_expiry_from = api_date_format(
        formData.membership_purchase_expiry_from
      );
      formData.membership_purchase_expiry_to = api_date_format(
        formData.membership_purchase_expiry_to
      );
      if (formData.expiry_in !== "custom") {
        formData.membership_purchase_expiry_to = moment(
          formData.membership_purchase_expiry_from,
          "YYYY-MM-DD"
        )
          .add(formData.expiry_in, "days")
          .format("YYYY-MM-DD");
      }
    }
    if (formData.filter_name) {
      delete formData.filter_name;
    }
    if (formData.filter_From) {
      delete formData.filter_From;
    }

    const result = await subscriber_list_for_member_with_filter(
      formData.page ? formData.page : page,
      formData.RowsNumber ? formData.RowsNumber : rowsPerPage,
      searchText ? searchText : flterData.search_text,
      formData
    );

    if (result.code === 200) {
      const data = result.event_subscriber.map((member, index) => {
        let completed_date = "N/A";
        let call_history = {
          is_checked: false,
        };
        if (member.call_history) {
          call_history = member.call_history;
        }
        if (member.wheel_of_life_completed_date) {
          completed_date = dd_date_format(member.wheel_of_life_completed_date);
        }

        let membership_expire = "N/A";
        if (member?.membership_purchase_expiry) {
          membership_expire = dd_date_format(
            member?.membership_purchase_expiry
          );
        }
        let nurture_name = "N/A";
        if (member.nurture?.first_name) {
          nurture_name =
            member.nurture.first_name + " " + member.nurture.last_name;
        }

        return {
          ...member,
          name: member.first_name + " " + member.last_name,
          created_at: dd_date_format(member.createdAt),
          contact_number: member.contact_number ? member.contact_number : "N/A",
          completed_date: completed_date,
          membership_expire: membership_expire,

          nurture_name: nurture_name,
          user_coins: get_kmb_number(member.coins_count),
          last_login_activity: member.last_login_activity
            ? get_start_date_end_time(member.last_login_activity)
            : "N/A",
          call_history,
        };
      });
      handle_chips_data(flterData, formData);
      setMembersData(data);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    if (!data.date) {
      delete data.date;
      data.from_date = null;
      data.is_date_range = false;
      data.to_date = null;
    }
    if (!data.coins) {
      delete data.coins;
      data.coins_from = 0;
      data.coins_range = false;
      data.coins_to = 0;
    }
    if (!data.membership_expiry) {
      delete data.membership_expiry;
      data.membership_purchase_expiry_from = new Date();
      data.membership_purchase_expiry_to = new Date();
      data.expiry_in = 3;
      data.member_ship_expiry = "";
    }
    setfinal(true);
    getMembersList(data);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("member_rows", event.target.value);
    localStorage.setItem("member_page_number", 0);
    setPageCount(1);
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleQuestionsAnswers = (value) => {
    navigate(`/members/questions-answers?member_id=${value._id}`, {
      state: value,
    });
  };

  const handleDetail = (value) => {
    navigate(`/members/profile/${value._id}`, {
      state: value,
    });
  };

  const handlePersonelNotes = (value) => {
    navigate(`/members/personel-notes/${value._id}`, {
      state: value,
    });
  };

  const handleSubscriber = (value) => {
    navigate(`/members/subscribers/${value._id}`, {
      state: value,
    });
  };
  const change_lead_status = (value) => {
    setSelectedMember(value);
    setChangeStatus(true);
  };

  const handleUpdateStatus = (value, lead_status_history) => {
    let index = membersData.findIndex((user) => user._id == leadStatusOld._id);
    let temp_state = [...membersData];
    let temp_element = { ...temp_state[index] };
    temp_element.lead_status = value;
    temp_element.lead_status_history = lead_status_history;
    temp_state[index] = temp_element;
    setMembersData(temp_state);
    handleCloseLeadStatus();
  };

  const handleSendMessage = async (id) => {
    handleAddChat(id);
  };

  const handleUpdateAllowCall = (value) => {
    setSelectedObject(value);
    setOpenAllowCall(true);
  };

  const MENU_OPTIONS = [
    {
      label: "View Subscriptions",
      icon: "eva:eye-fill",
      handleClick: handleSubscriber,
    },
    {
      label: "Personal Notes",
      icon: "gridicons:pages",
      handleClick: handlePersonelNotes,
    },
    {
      label: "Update Call Functionality",
      icon: "material-symbols-light:call-sharp",
      handleClick: handleUpdateAllowCall,
    },
    {
      label: "Questions Answers",
      icon: "ant-design:lock",
      handleClick: handleQuestionsAnswers,
    },
  ];
  if (consultant_both) {
    MENU_OPTIONS.splice(2, 0, {
      label: "View Profile",
      icon: "eva:eye-fill",
      handleClick: handleDetail,
    });
  }

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleOpenLeadStatus = () => {
    setChangeStatus(true);
  };

  const handleCloseLeadStatus = () => {
    setChangeStatus(false);
    setleadStatusOld({});
    setSelectedMember({});
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
    setIschangeFilter(true);
  };

  const handleIsCountry = (event) => {
    setFilterData((values) => ({ ...values, country: event }));
  };
  const handleChangeSave = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSaveFilter((values) => ({ ...values, [name]: value }));
    if (value == "new") {
      setSaveFilter((values) => ({ ...values, ["filter_name"]: null }));
      setFilterData(EMPTY_FILTER);
    }
  };

  const handleSave = (event, name) => {
    setSaveFilter((values) => ({ ...values, [name]: event }));
  };
  const handleExistingFilter = (event) => {
    setIschangeFilter(false);
    if (event == null || SaveFilter.filter_From == "new") {
      setFilterData(EMPTY_FILTER);
      setSaveFilter((values) => ({ ...values, ["filter_name"]: null }));
    } else {
      setFilterData(event.filter_object);
    }
  };

  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
    setIschangeFilter(true);
  };
  const handleChangeSort = (event, name) => {
    let filter_Data = {
      ...filterData,
      [name]: event,
    };
    setFilterData(filter_Data);
    getMembersList(filter_Data);
    localStorage.setItem(
      "nurture_member_filter_data",
      JSON.stringify(filter_Data)
    );
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getMembersList(filterData);
    localStorage.setItem("member_filter_data", JSON.stringify(filterData));
    localStorage.setItem("member_save", JSON.stringify(SaveFilter));
    setfinal(ischangeFilter);
    handleCloseFilterDrawer();
  };

  const handleClickPages = (event, row) => {
    setPages(row);
    setAnchorElPages(event.currentTarget);
  };

  const handleClosePopUpPages = () => {
    setAnchorElPages(null);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    localStorage.removeItem("member_filter_data");
    localStorage.removeItem("member_save");
    setSaveFilter({
      filter_name: null,
      filter_From: "new",
    });
    getMembersList(EMPTY_FILTER);
    setFilterDrawerState(false);
    setfinal(false);
    setIschangeFilter(false);
  };
  const get_active_lead_status = async () => {
    const result = await active_lead_status_api();
    if (result.code === 200) {
      setLeadStatus(result.lead_status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    get_active_lead_status();
  }, []);
  useEffect(() => {
    let PageFromLocalStorage = localStorage.getItem("member_page_number");
    let rowsFromLocalStorage = localStorage.getItem("member_rows");
    const pageNumber = Number(PageFromLocalStorage) || 1;
    const RowsNumber = Number(rowsFromLocalStorage) || 50;
    setRowsPerPage(RowsNumber);
    setPage(PageFromLocalStorage <= 0 ? 0 : PageFromLocalStorage - 1);
    setPageCount(pageNumber <= 0 ? 1 : pageNumber);
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("member_filter_data");
    let find_filter_SAVE = localStorage.getItem("member_save");
    let filter_data_save;
    if (find_filter_SAVE) {
      filter_data_save = JSON.parse(find_filter_SAVE);
      if (filter_data_save) {
        setSaveFilter(filter_data_save);
      }
    }
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    if (PageFromLocalStorage) {
      filter_data.page =
        PageFromLocalStorage <= 0 ? 0 : PageFromLocalStorage - 1;
    }
    if (RowsNumber) {
      filter_data.RowsNumber = RowsNumber;
    }
    getMembersList(filter_data);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setMembersData((old) =>
      old.map((member) => {
        if (member._id == onlineOfflineData.user_id) {
          member.is_online = onlineOfflineData.is_online;
        }
        return member;
      })
    );
  }, [onlineOfflineData]);
  useEffect(() => {
    if (!openCallType) {
      if (index) {
        const list = [...membersData];
        list[index]["call_history"]["is_checked"] = false;
        setMembersData(list);
      }
    }
  }, [openCallType]);
  const headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Contact Number", key: "contact_number" },
  ];
  const handleExportClick = () => {
    download_csv_file(headers, membersData);
  };
  let MENU_OPTIONS_MAIN = [
    {
      label: "Export Csv",
      icon: "ic:round-cloud-download",
      handleClick: handleExportClick,
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <div className="col-6">
            <h2>Members List</h2>
          </div>
          <div className="col-6 d-flex pe-0">
            <MUIAutocomplete
              inputLabel="Sort By"
              size="small"
              selectedOption={filterData.sort_by}
              setSelectedOption={(e) => {
                handleChangeSort(e, "sort_by");
              }}
              optionsList={sort_by_array}
              name="chip_label"
            />
            <button
              className="small-contained-button ms-2  mb-4 "
              onClick={handleOpenFilterDrawer}
              style={{ width: "175px" }}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>{" "}
            {/* {userInfo.is_super_delegate && ( */}
            {isAccess.member_export_csv && (
              <button
                className="small-contained-button ms-2  mb-4 "
                onClick={handleExportClick}
                style={{ width: "185px" }}
              >
                Export Csv
              </button>
            )}
          </div>
        </div>

        <div className="row">
          <FilteredChip
            data={formDataUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            REMOVE_ITEMS={REMOVE_ITEMS}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
            tabName={final ? "member" : ""}
          />
          <div className="col-12 mt-4">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={membersData}
              selected={selected}
              MENU_OPTIONS={MENU_OPTIONS}
              setSelected={setSelected}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>

        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Member Filter"
          componentToPassDown={
            <MemberFilter
              filterData={filterData}
              handleChange={handleChange}
              handleExistingFilter={handleExistingFilter}
              setFilterData={setFilterData}
              SaveFilter={SaveFilter}
              handleIsCountry={handleIsCountry}
              handleChangeOthers={handleChangeOthers}
              handleChangeSave={handleChangeSave}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
              handleSave={handleSave}
              is_nurture_filter={true}
              consultant_both={isAccess.Show_nurture_in_filter}
              filter_on_tab_name={"member"}
            />
          }
        />
        <MemberPagesPopOver
          handleClose={handleClosePopUpPages}
          anchorEl={anchorElPages}
          id={id_pages}
          open={openPages}
          program={Pages}
        />
      </div>
      <WhatsappPopUpModel
        open={changeStatusOpen}
        setOpen={setChangeStatusOpen}
        title={"Lead Status History"}
        show_date_and_income={true}
        componentToPassDown={
          <LeadStatusHistory
            setOpen={setChangeStatusOpen}
            history={history}
            leadStatusData={leadStatusData}
          />
        }
      />
      <GeneralPopUpModel
        open={openCallType}
        setOpen={setOpenCallType}
        title={"Call History Note"}
        componentToPassDown={
          <CallTypeNotes
            setOpen={setOpenCallType}
            memberObject={memberObject}
            getMembersList={getMembersList}
            filterData={filterData}
            setindex={setindex}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={changeStatus}
        onOpenDrawer={handleOpenLeadStatus}
        onCloseDrawer={handleCrossedLeadStatis}
        pageTitle={"Change Lead Status"}
        componentToPassDown={
          <ChangeLeadStatus
            selectedObject={selectedMember}
            leadStatus={leadStatus}
            handleUpdateStatus={handleUpdateStatus}
          />
        }
      />
      <CustomConfirmation
        open={openAllowCall}
        setOpen={setOpenAllowCall}
        title={`Are you sure you want to ${
          selectedObject?.is_call_allowed ? "disable" : "enable"
        } call functionality for this user?`}
        handleAgree={handleAllowCall}
      />
    </>
  );
}
