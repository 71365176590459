import React, { useEffect, useRef, useState } from "react";
import { Card, CircularProgress, Fab, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import { s3baseUrl } from "src/config/config";
import { formatTime } from "src/utils/constants";
import moment from "moment";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { Participants } from "./components";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import CopyLiveEmbedCode from "./components/CopyLiveEmbedCode";
import WebRTCStreamSettings from "./components/Participants/WebRTCStreamSettings";
const momenttz = require("moment-timezone");
import IVSBroadcastClient from "amazon-ivs-web-broadcast";

let micError =
  "Microphone access is denied. Please grant permission in your browser settings.";
let cameraError =
  "'Camera permission is denied. Please enable it in your browser settings.'";

const streamConfig = {
  maxResolution: {
    width: 1280,
    height: 720,
  },
  maxFramerate: 30,
  maxBitrate: 3500,
};

const Background = ({ imageUrl }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    }}
  />
);

export default function LiveStream(props) {
  const { feedDetail, liveStream, isFullScreen, setIsFullScreen, navigateTo } =
    props;
  const IVSClientRef = useRef();
  const activeCamRef = useRef(null);
  const activeMicRef = useRef(null);
  const { topic } = useParams();
  const navigate = useNavigate();
  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [openEmbedCode, setOpenEmbedCode] = useState(false);
  const [openParticipants, setOpenParticipants] = useState(false);
  const [openEndStream, setOpenEndStream] = useState(false);
  const [isScreenShared, setIsScreenShared] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openSettings, setOpenSettings] = useState(false);
  const { socket, userInfo } = useContentSetting();
  const [participants, setParticipants] = useState([userInfo]);
  const [participantsCount, setParticipantsCount] = useState(0);
  const [hostUsers, setHostUsers] = useState([]);
  const [timer, setTimer] = useState(-1);

  const handleOpenParticipantsDrawer = () => {
    setOpenParticipants(true);
  };

  const handleCloseParticipantsDrawer = () => {
    setOpenParticipants(false);
  };

  const handleOpenSettingsDrawer = () => {
    setOpenSettings(true);
  };

  const handleCloseSettingsDrawer = () => {
    setOpenSettings(false);
  };

  const handleEndStreamConfirmation = () => {
    setOpenEndStream(true);
  };

  const handleEndStream = () => {
    if (!IVSClientRef.current) return;
    console.log(IVSClientRef.current, "IVSClientRef.current");
    IVSClientRef.current.stopBroadcast();
    window.cameraStream.getTracks().forEach((track) => track.stop());
    window.microphoneStream.getTracks().forEach((track) => track.stop());
    socket.emit("end_stream_event", { feed_id: topic });
  };

  const handleMuteToggle = () => {
    let audioStream = IVSClientRef.current.getAudioInputDevice(
      activeMicRef.current
    );
    audioStream.getAudioTracks()[0].enabled = isMuted;
    let postData = {
      module_id: topic,
      is_video_muted: !isMuted,
    };
    socket.emit("is_video_muted", postData);
    setIsMuted(!isMuted);
  };

  const handlePlayPauseToggle = async () => {
    let videoStream = IVSClientRef.current.getVideoInputDevice(
      activeCamRef.current
    ).source;
    videoStream.getVideoTracks()[0].enabled = isPaused;
    let postData = {
      module_id: topic,
      is_video_paused: !isPaused,
    };
    socket.emit("is_video_paused", postData);
    setIsPaused(!isPaused);
  };

  const live_stream_started_receiver = (data) => {
    console.log(data, "live_stream_started_receiver");
    startStream(data);
  };

  const preventReload = (e) => {
    if (
      e.key === "F5" ||
      (e.ctrlKey && e.key === "r") ||
      (e.metaKey && e.key === "r")
    ) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
    handleEndStream();
  };

  useEffect(() => {
    if (isLoading) return;
    let interval;
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("keydown", preventReload);
    window.addEventListener("unload", handleEndStream);
    return () => {
      window.removeEventListener("keydown", preventReload);
      window.removeEventListener("unload", handleEndStream);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      handleEndStream();
      clearInterval(interval);
    };
  }, []);

  const handleDefaultDevices = (videoTracks, audioTracks, stream) => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audioDevice = devices.find(
        (device) => device.deviceId === audioTracks.getSettings().deviceId
      );
      activeMicRef.current = audioDevice.deviceId;

      let videoCamSetting = videoTracks.getSettings();
      const videoDevice = devices.find(
        (device) => device.deviceId === videoCamSetting.deviceId
      );
      activeCamRef.current = videoDevice.deviceId;
    });
    stream.getTracks().forEach((track) => track.stop());
  };

  const hanldeAddVideoDevice = async (client, activeCam) => {
    window.cameraStream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: activeCam,
        width: {
          ideal: streamConfig.maxResolution.width,
        },
        height: {
          ideal: streamConfig.maxResolution.height,
        },
      },
    });
    if (isPaused) {
      window.cameraStream.getVideoTracks()[0].enabled = false;
    }
    client.addVideoInputDevice(window.cameraStream, activeCam, { index: 0 });
    activeCamRef.current = activeCam;
  };

  const hanldeAddAudioDevice = async (client, activeMic) => {
    window.microphoneStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: activeMic },
    });
    if (isMuted) {
      window.microphoneStream.getAudioTracks()[0].enabled = false;
    }
    client.addAudioInputDevice(window.microphoneStream, activeMic);
    activeMicRef.current = activeMic;
  };

  const startStream = async (streamData) => {
    const { ingest_endpoint, stream_key } = streamData;

    const client = IVSBroadcastClient.create({
      streamConfig: streamConfig,
      ingestEndpoint: ingest_endpoint,
    });
    IVSClientRef.current = client;
    await hanldeAddVideoDevice(client, activeCamRef.current);
    await hanldeAddAudioDevice(client, activeMicRef.current);

    const previewEl = document.getElementById("preview");
    client.attachPreview(previewEl);
    setTimer(0);
    setIsLoading(false);
    try {
      await client.startBroadcast(stream_key);
    } catch (error) {
      console.error("Error starting stream:", error);
    }
  };

  useEffect(async () => {
    const mic = await navigator.permissions.query({ name: "microphone" });
    if (mic.state === "denied") {
      alert(micError);
      return;
    }

    const camera = await navigator.permissions.query({ name: "camera" });
    if (camera.state === "denied") {
      alert(cameraError);
      return;
    }

    const videoConstraints = {
      video: true,
      audio: true,
    };

    navigator.mediaDevices
      .getUserMedia(videoConstraints)
      .then((stream) => {
        const videoTracks = stream.getVideoTracks();
        const audioTracks = stream.getAudioTracks();
        const data = {
          user_id: userInfo._id,
          module_id: topic,
          module_name: "feed",
          user_type: "consultant_user",
        };

        socket.emit("live_stream_started", data);
        handleDefaultDevices(videoTracks[0], audioTracks[0], stream);
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
      });
  }, [socket]);

  useEffect(() => {
    socket.on("manage_stream_participant_receiver", (data) => {
      const user = data.user;
      let find_user = participants.find((u) => u._id === user._id);
      user.join_time = new Date();
      if (data.module_id === topic) {
        if (data.action === "user_joined") {
          if (!find_user) {
            setParticipants((old) => [user, ...old]);
          }
        } else {
          setParticipants((old) =>
            old.filter((participant) => participant._id !== user._id)
          );
        }
      }
    });

    socket.on("live_stream_started_receiver", live_stream_started_receiver);

    return () => {
      socket.off("manage_stream_participant_receiver");
      socket.off("live_stream_started_receiver");
    };
  }, [socket]);

  const settingData = {
    activeCam: activeCamRef.current,
    activeMic: activeMicRef.current,
    openSettings,
    IVSClient: IVSClientRef.current,
    hanldeAddVideoDevice,
    hanldeAddAudioDevice,
  };

  return (
    <Card className="live-stream-wrapper">
      {isLoading ? (
        <CircularProgress className="live-stream-loader" color="primary" />
      ) : (
        isPaused &&
        !isScreenShared && (
          <Background imageUrl={s3baseUrl + feedDetail.image.thumbnail_1} />
        )
      )}

      <div className="live-stream-header">
        <div className="stream-info">
          <Icon
            className="me-1"
            fontSize={16}
            style={{ color: "red" }}
            icon="carbon:dot-mark"
          />
          <span>Live</span>
        </div>
        {timer >= 0 && (
          <div className="call-timer-section">
            <div className="call-timer-box">{formatTime(timer)}</div>
          </div>
        )}
        <div
          className="stream-users-info"
          onClick={handleOpenParticipantsDrawer}
        >
          <Icon
            className="me-1"
            fontSize={16}
            icon="ic:baseline-remove-red-eye"
          />
          <span>{participants.length}</span>
        </div>
      </div>
      <canvas id="preview" className="w-100"></canvas>
      <div className="live-stream-footer">
        <div className="end-stream-btn" onClick={handleEndStreamConfirmation}>
          <Tooltip title="End Stream">
            <Fab
              style={{ backgroundColor: "red", color: "white" }}
              size="medium"
              variant="extended"
              aria-label="add"
            >
              <Icon
                className="stream-control-icon"
                icon="material-symbols:call-end"
              />
            </Fab>
          </Tooltip>
        </div>
        <div className="stream-controls-wrapper">
          <Tooltip title={isPaused ? "Play" : "Pause"}>
            <div className="stream-control">
              <Fab
                onClick={handlePlayPauseToggle}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon
                  className="stream-control-icon"
                  icon={`iconoir:video-camera${isPaused ? "-off" : ""}`}
                />
              </Fab>
            </div>
          </Tooltip>
          <Tooltip title={isMuted ? "Unmute" : "Mute"}>
            <div className="stream-control">
              <Fab
                onClick={handleMuteToggle}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon
                  className="stream-control-icon"
                  icon={`${
                    isMuted ? "material-symbols:mic-off" : "ic:outline-mic"
                  }`}
                />
              </Fab>
            </div>
          </Tooltip>
          {/* <Tooltip
            title={isScreenShared ? "Stop Screen Sharing" : "Share Screen"}
          >
            <Fab
              onClick={isScreenShared ? stopScreenShare : handleScreenShare}
              className="stream-control-button"
              size="medium"
              variant="extended"
              aria-label="screen-share"
            >
              <Icon
                className="stream-control-icon"
                icon={`fluent:share-screen-${
                  isScreenShared ? "stop" : "start"
                }-28-regular`}
              />
            </Fab>
          </Tooltip> */}
          <Tooltip title={isFullScreen ? "Exit Full Screen" : "Full Screen"}>
            <div className="stream-control">
              <Fab
                onClick={() => setIsFullScreen(!isFullScreen)}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon
                  className="stream-control-icon"
                  icon={`${
                    isFullScreen
                      ? "ic:baseline-fullscreen-exit"
                      : "ic:baseline-fullscreen"
                  }`}
                />
              </Fab>
            </div>
          </Tooltip>
          <Tooltip title="Copy Embed Code">
            <div className="stream-control">
              <Fab
                onClick={() => setOpenEmbedCode(true)}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon className="stream-control-icon" icon="solar:code-bold" />
              </Fab>
            </div>
          </Tooltip>
        </div>
        <div className="settings-stream-btn" onClick={handleOpenSettingsDrawer}>
          <Fab style={{}} size="medium" variant="extended" aria-label="add">
            <Icon
              className="stream-control-icon"
              icon="icon-park-solid:setting-two"
            />
          </Fab>
        </div>
      </div>
      <CustomConfirmation
        open={openEndStream}
        setOpen={setOpenEndStream}
        title={"Are you sure you want to end this live stream?"}
        handleAgree={() => navigate(navigateTo)}
      />
      <CustomDrawer
        isOpenDrawer={openParticipants}
        onOpenDrawer={handleOpenParticipantsDrawer}
        onCloseDrawer={handleCloseParticipantsDrawer}
        pageTitle="Live Stream Participants"
        componentToPassDown={
          <Participants
            module_id={topic}
            openParticipants={openParticipants}
            participants={participants}
            setParticipants={setParticipants}
            handleMakeHost={() => {}}
            handleSelectTile={() => {}}
            liveStream={liveStream}
            hostUsers={hostUsers}
            setHostUsers={setHostUsers}
            participantsCount={participantsCount}
            setParticipantsCount={setParticipantsCount}
          />
        }
      />

      <GeneralModelBox
        open={openEmbedCode}
        setOpen={setOpenEmbedCode}
        className="feed-poll-popup-modal"
        componentToPassDown={<CopyLiveEmbedCode module_id={topic} />}
      />

      <CustomDrawer
        isOpenDrawer={openSettings}
        onOpenDrawer={handleOpenSettingsDrawer}
        onCloseDrawer={handleCloseSettingsDrawer}
        pageTitle="Live Streams Settings"
        componentToPassDown={<WebRTCStreamSettings {...settingData} />}
      />
    </Card>
  );
}
